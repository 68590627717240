import React from 'react'
import '../../assets/templateStyles.css'

export default function About() {
    return (
        <section>
            <div className='template-width'>
                <h1>About</h1>
            </div>
        </section>
    )
}
